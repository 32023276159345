.loginpage {
  background: linear-gradient(116.82deg, #c39bf5 0%, rgba(115, 80, 158, 0) 100%),
    #03164d;
  position: fixed;
  width: 100%;
}

.logo-div {
  display: flex;
  justify-content: left;
  height: 0px;
  margin-left: 50px;
}

.logo-sym {
  width: 12%;
  height: 150px;
  filter: invert(0);
}

.copyright {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
