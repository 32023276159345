.div-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.drop-file-input {
  margin: 50px;
  position: relative;
  width: 60%;
  min-width: 400px;
  max-width: 1000px;
  height: 20%;
  min-height: 100px;
  max-height: 180px;
  border: 2px dashed;
  border-color: #302eb5;
  border-radius: 20px;
  margin-left: -100px;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.4;
}

.icon {
  width: 60px;
  height: 60px;
  opacity: 0.6;
}
.drop-file-input__label {
  text-align: center;
  opacity: 0.8;
  font-weight: 600;
  padding: 10px;
  font-size: 20px;
  color: rgb(59, 59, 59);
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
  margin-left: -100px;
}

.btn-gen {
  height: 50px;
  width: 200px;
  background-color: #302eb5 !important;
  border-radius: 8px;
  border-color: #302eb5 !important;
  transition-duration: 0.2s !important;
  margin-left: 50px;
  margin-top: -75px;
}

.btn-gen:hover {
  background-color: #2a28c7 !important;
  border-color: #2a28c7 !important;
  box-shadow: 0px 5px 15px #2a28c7 !important;
}

.btn-gen:focus {
  box-shadow: none !important;
}

.btn-clear:focus {
  box-shadow: none !important;
}

.btn-clear:hover {
  box-shadow: 0px 5px 15px rgb(204, 196, 196) !important;
}
.btn-clear {
  height: 50px;
  width: 200px;
  background-color: white !important;
  border-radius: 8px;
  border-color: #302eb5 !important;
  margin-top: -75px;
}
