.h2--name {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.015em;
  color: rgba(14, 14, 14, 0.5);
}

.login {
  background-color: #e2e3ea !important;
  border-radius: 50px !important;
  width: 400px;
  height: 550px;
}

.btn--login {
  background-color: #f5a068 !important;
  background: linear-gradient(
      270deg,
      #f5a068 2.48%,
      rgba(245, 160, 104, 0) 100%
    ),
    #ff606c !important;
  border-radius: 50px !important;
  border-color: #e2e3ea !important;
  height: 60px;
  width: 325px !important;
  text-transform: uppercase;
  transition-duration: 0.2s !important;
}

.btn--login:hover {
  background-color: #f5a068 !important;
  box-shadow: 0px 5px 15px #f5a068 !important;
  border-color: #f5a068 !important;
}

.login--text {
  color: white;
  font-size: 150%;
  font-weight: 600;
  letter-spacing: 2px;
}

.form--control {
  border-radius: 50px !important;
  height: 60px;
  width: 325px !important;
}

.link {
  color: grey;
  text-decoration: underline;
}

.link:hover {
  color: blue;
}
