.sidebar {
  height: 90vh;
  background-color: #020227;
}

.logo {
  filter: invert(0.8);
  width: 75%;
  margin-top: 50px;
}

.btn-logout-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  height: 10vh;
  background-color: #020227;
}

.btn-logout {
  height: 50px;
  width: 250px;
  background-color: #4e4aff !important;
  border-radius: 8px;
  border-color: #4e4aff !important;
  transition-duration: 0.2s !important;
}

.btn-logout:hover {
  background-color: #2a28c7 !important;
  border-color: #2a28c7 !important;
}

.btn-sidebar {
  height: 50px;
  width: 260px;
  background-color: #020227 !important;
  border-radius: 8px;
  border-color: #020227 !important;
  transition-duration: 0.2s !important;
}

.btn-sidebar:hover {
  background-color: #484773 !important;
  border-color: #484773 !important;
}

.btn-sidebar:focus {
  background-color: #484773 !important;
  border-color: #484773 !important;
  box-shadow: none !important;
}

.btn-qrcode-text {
  position: relative;
  right: 5px;
}

.btn-logout-text {
  position: relative;
  right: 10px;
}

.btn-icon {
  position: relative;
  right: 55px;
  margin-left: 0px;
  margin-bottom: 5px;
  filter: invert(1);
}

.btn-qricon {
  position: relative;
  width: 25px;
  height: 25px;
  right: 5px;
  bottom: 2px;
  margin-left: 0px;
  margin-right: 8px;
  filter: brightness(0) invert(1);
}

.menu-box {
  height: 75px;
  margin-left: 15px;
  padding: 10px;
  padding-right: 0px;
  width: 90%;
}

.menu-bars {
  text-decoration: none;
  background-color: #020227;
  font-size: 17px;
  color: white;
  border-radius: 8px;
  padding-top: 10px;
  padding-left: 20px;
}

.menu-bars:hover {
  text-decoration: none;
  color: white;
  background-color: #484773;
}

.menu-bars-div {
  height: 60px;
}
