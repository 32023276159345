.dashboard {
  background-color: #f5f6f7;
}

.workarea {
  height: 100vh;
  background-color: #f5f6f7;
}

.topbar {
  height: 125px;
  background-color: white;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
}

.topbar-text {
  font-size: 45px;
  margin: 30px;
  font-weight: 500;
  min-width: 500px;
  color: #05043e;
}

.lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid blue;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: blue transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
