.loginpage {
  background: linear-gradient(116.82deg, #c39bf5 0%, rgba(115, 80, 158, 0) 100%),
    #03164d;
  position: fixed;
  width: 100%;
}

.logo-div {
  display: flex;
  justify-content: left;
  height: 0px;
  margin-left: 50px;
}

.logo-sym {
  width: 12%;
  height: 150px;
  filter: invert(0);
}

.reset--card {
  background-color: #e2e3ea !important;
  border-radius: 50px !important;
  width: 400px;
  height: 350px;
}

.btn--reset {
  background-color: #f5a068 !important;
  background: linear-gradient(
      270deg,
      #f5a068 2.48%,
      rgba(245, 160, 104, 0) 100%
    ),
    #ff606c !important;
  border-radius: 50px !important;
  border-color: #e2e3ea !important;
  height: 60px;
  width: 325px !important;
  transition-duration: 0.2s !important;
}

.btn--reset:hover {
  background-color: #f5a068 !important;
  box-shadow: 0px 5px 15px #f5a068 !important;
  border-color: #f5a068 !important;
}
